import styled, { createGlobalStyle, css } from 'styled-components';
import {
  fontSizeMd,
  fontSizeSm,
  spacingInsetLg,
  spacingStackXs,
  fontFamilyBase,
  fontSizeXxs,
  fontSizeXs,
  lineHeightComfy,
  colorInterfaceDarkDown,
  colorInterfaceLightPure,
  spacingInsetXxs,
  spacingInsetMd,
  spacingInsetXs,
  spacingInsetSm,
  colorInterfaceLightUp,
  lineHeightMedium,
  borderRadiusMd,
  fontSizeXxxs,
  colorInterfaceLightDown,
  fontWeightBold,
  spacingInlineXxl,
  spacingStackSm,
  spacingInlineSm,
  spacingInlineXs,
  spacingStackXxs,
  colorInterfaceDarkPure,
  fontWeightRegular,
  colorStatusNegativeUp,
  spacingInlineXxs,
} from '@useblu/ocean-tokens/web/tokens';

import { Button, Grid, Tag } from '@useblu/ocean-react';
import { Devices } from 'utils/getDeviceMedia';

export const PixPaymentContainer = styled.div`
  @media ${Devices.mobile} {
    background-color: ${colorInterfaceLightPure};
    min-height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
  }
`;

export const GridContainer = styled(Grid.Container)`
  .ods-col-md-10 {
    padding: 0;
  }
`;

export const Bar = styled.div`
  margin-bottom: ${spacingInsetXxs};
  margin-top: ${spacingStackSm};
  width: 100%;

  .ods-topbar {
    flex-wrap: nowrap;
    height: auto;
  }

  .ods-topbar-title {
    margin: 0;
    font-size: ${fontSizeMd};
  }

  .ods-topbar-left {
    margin-left: 0;
  }

  @media ${Devices.mobile} {
    background-color: ${colorInterfaceLightPure};
    display: flex;
    left: 0;
    margin: 0;
    padding: 17px 16px;
    position: fixed;
    top: 0;
    z-index: 10;

    .ods-topbar {
      margin-bottom: 0;
      max-width: 540px;
    }

    .ods-topbar-title {
      font-size: ${fontSizeSm};
    }

    .ods-topbar-left {
      margin-left: 0;
      margin-right: ${spacingStackXs};
    }
  }
`;

export const Description = styled.p`
  color: ${colorInterfaceDarkDown};
  font-family: ${fontFamilyBase};
  font-size: ${fontSizeXs};
  line-height: ${lineHeightComfy};
  margin-bottom: ${spacingInsetMd};

  @media ${Devices.mobile} {
    margin: 68px 0 ${spacingInsetMd};
  }
`;

export const Table = styled.table`
  margin: 0;
  width: auto;

  tr,
  td {
    border: none;
  }
`;

const baseTableRow = css`
  & > td {
    padding: 0;
    vertical-align: top;

    &:nth-child(1) {
      width: 200px;

      & > p {
        color: ${colorInterfaceDarkDown};
        text-align: end;
      }
    }

    &:nth-child(2) {
      padding-left: ${spacingInsetSm};

      & > p {
        color: ${colorInterfaceDarkPure};
        font-weight: ${fontWeightRegular};
      }
    }

    & > p {
      font-family: ${fontFamilyBase};
      font-size: ${fontSizeXs};
      line-height: ${lineHeightComfy};
      margin: 0;
    }
  }

  @media ${Devices.mobile} {
    display: flex;
    flex-direction: column;

    & > td {
      &:nth-child(1) {
        p {
          text-align: start;
        }
      }

      &:nth-child(2) {
        padding-left: 0;
      }
    }
  }
`;

export const TableRow = styled.tr`
  ${baseTableRow}
`;

export const ContentContainer = styled.div`
  @media ${Devices.mobile} {
    border: none;
  }
`;

export const TableDataTransaction = styled.div`
  border: 1px solid ${colorInterfaceLightDown};
  border-radius: ${borderRadiusMd};
  padding-right: ${spacingInlineXs};
  @media ${Devices.mobile} {
    border: none;
    padding: 0 16px;
  }
`;

export const ValueTableRow = styled.tr`
  ${baseTableRow}

  &.session {
    td {
      p {
        padding-bottom: ${spacingInsetLg};
      }
    }
  }

  & > td {
    p {
      padding: 30px 0 10px;
    }
    &:nth-child(2) {
      p {
        font-weight: ${fontWeightBold};
      }
    }
  }

  @media ${Devices.mobile} {
    & > td {
      p {
        padding: 0;
      }

      &:nth-child(2) {
        padding-bottom: ${spacingInsetSm};
      }
    }
  }
`;

export const DateTableRow = styled.tr`
  ${baseTableRow}

  &.session {
    td {
      p {
        padding-bottom: ${spacingInsetLg};
      }
    }
  }

  td {
    p {
      padding-bottom: ${spacingStackXxs};
    }
  }

  @media ${Devices.mobile} {
    &.session {
      td:first-of-type > p {
        padding-bottom: 0;
      }

      td:last-of-type > p {
        padding-bottom: 0;
        margin-bottom: ${spacingStackXs};
      }
    }

    td:first-of-type {
      p {
        padding: 0;
      }
    }

    td:last-of-type {
      p {
        padding-bottom: 0;
        margin-bottom: ${spacingStackXs};
      }
    }
  }
`;

export const DescriptionTableRow = styled.tr`
  ${baseTableRow}

  td {
    p {
      padding-bottom: ${spacingInsetLg};
      word-break: break-all;
    }
  }

  @media ${Devices.mobile} {
    td {
      p {
        padding: 0;
      }

      &:nth-child(2) {
        padding-bottom: ${spacingInsetSm};
      }
    }
  }
`;

export const RecipientTableRow = styled.tr`
  ${baseTableRow}

  td {
    &:nth-child(2) {
      padding-bottom: ${spacingInsetXs};
    }
  }

  @media ${Devices.mobile} {
    td {
      p {
        padding: 0;
      }

      &:nth-child(2) {
        padding-bottom: ${spacingInsetSm};
      }
    }
  }
`;

export const InfoBankTableRow = styled.tr`
  ${baseTableRow}

  @media ${Devices.mobile} {
    td {
      p {
        padding: 0;
      }

      &:nth-child(2) {
        padding-bottom: ${spacingInsetSm};
      }
    }
  }
`;

export const CodeAuthenticationTableRow = styled.tr`
  ${baseTableRow}

  td {
    p {
      padding-bottom: ${spacingStackSm};
    }
  }

  @media ${Devices.mobile} {
    td {
      p {
        padding-bottom: 0;
      }

      &:nth-child(2) {
        padding-bottom: ${spacingInsetSm};
        word-break: break-all;
      }
    }
  }
`;

export const OperationCodeTableRow = styled.tr`
  ${baseTableRow}

  td {
    p {
      padding-top: ${spacingInsetLg};
      padding-bottom: ${spacingStackXxs};
    }
  }

  @media ${Devices.mobile} {
    td {
      p {
        padding: 0;
      }

      &:nth-child(2) {
        padding-bottom: ${spacingInsetSm};
        word-break: break-all;
      }
    }
  }
`;

export const TableDataTransactionFooter = styled.div`
  border: 1px solid ${colorInterfaceLightDown};
  border-radius: 0 0 ${borderRadiusMd} ${borderRadiusMd};
  padding: 16px;

  p {
    color: ${colorInterfaceDarkDown};
    font-family: ${fontFamilyBase};
    font-size: ${fontSizeXxs};
    line-height: ${lineHeightComfy};
    margin: 0;
    padding-bottom: 0;
  }

  @media ${Devices.mobile} {
    border: none;
    border-top: 1px solid ${colorInterfaceLightDown};
  }
`;

export const DownloadVoucherContainer = styled.div`
  border-top: 1px solid ${colorInterfaceLightDown};
  display: flex;
  justify-content: flex-end;
  margin-top: ${spacingInsetLg};
  margin-bottom: ${spacingInsetSm};
  padding: ${spacingInsetSm} 0;
  gap: ${spacingInlineXxs};

  & > .ods-btn--text-critical {
    background: ${colorStatusNegativeUp};
  }

  @media ${Devices.mobile} {
    border: 0;
    justify-content: center;
    padding: 0 ${spacingInsetSm};

    & > button {
      flex: 1;
    }
  }
`;

export const BreadcrumbStyle = createGlobalStyle`
  .light-main .dark-main-content .breadcrumbs {
    border: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 760px;
    width: 100%;
  }

  .breadcrumbs-container {
    margin-top: 32px;
    padding: 0;
  }

  @media ${Devices.notebook} {
    .light-main .dark-main-content .breadcrumbs {
      max-width: 638px;
    }
  }

  @media ${Devices.tablet} {
    .light-main .dark-main-content .breadcrumbs {
      max-width: 600px;
    }
  }
`;

export const TagStatusTransaction = styled(Tag)`
  margin-bottom: ${spacingInsetSm};
  margin-top: ${spacingInsetMd};
  padding: 3.5px ${spacingInsetXs};
  width: fit-content;

  @media ${Devices.mobile} {
    margin: ${spacingInlineXxl} ${spacingInsetSm} ${spacingInsetSm};
  }
`;

export const PaymentCostsContainer = styled.div`
  border: 1px solid ${colorInterfaceLightDown};
  border-radius: ${borderRadiusMd};
  margin-top: ${spacingInsetLg};

  table {
    margin: ${spacingInlineSm} 0;

    td {
      p {
        padding: ${spacingInsetXxs} 0;
      }
    }
  }

  @media ${Devices.mobile} {
    border: none;

    table {
      margin: 0 ${spacingInlineXs};
      margin-top: ${spacingStackXxs};

      tr {
        padding-bottom: ${spacingInsetSm};
      }

      td {
        p {
          padding: 0;
        }
      }
    }
  }
`;

export const PaymentCostsHeader = styled.h3`
  color: ${colorInterfaceDarkDown};
  background-color: ${colorInterfaceLightUp};
  font-family: ${fontFamilyBase};
  font-size: ${fontSizeXxxs};
  line-height: ${lineHeightMedium};
  margin-bottom: 0;
  padding: 9.5px ${spacingInsetSm};
`;

export const LinkButton = styled(Button).attrs(() => ({
  variant: 'text',
}))`
  &&& {
    padding: 0;
    height: auto;
    font-size: ${fontSizeXxs};

    &:hover,
    &:active,
    &:focus {
      background: none;
    }

    @media ${Devices.mobile} {
      min-width: auto;
    }
  }
`;

export const ProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${spacingStackXs};
`;
