global.$ = require('jquery');
global.jQuery = $;

require('jquery-ujs');
require('utils/snippets/input-masks');
require('utils/fullstory');
require('select2/select2');
require('select2/i18n/pt-BR');
require('utils/snippets/client');
require('utils/snippets/lytics');

global.jcep = require('jcep');

const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');

ReactRailsUJS.useContext(componentRequireContext);

require('global_components');
