/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Drawer, Typography } from '@useblu/ocean-react';
import { normalizeMoney } from '@useblu/utils';
import { normalizeSeparator } from 'normalizers';
import * as S from './styles';
import useAdvancementSimulationDetailsDrawer from './hooks/useAdvancementSimulationDetailsDrawer';

const AdvancementSimulationDetailsDrawer = ({ balance }) => {
  const { isDrawerOpen, closeDrawer, data } =
    useAdvancementSimulationDetailsDrawer();

  return (
    <Drawer
      open={isDrawerOpen}
      onDrawerClose={closeDrawer}
      overlayClose={closeDrawer}
    >
      <S.DrawerContent>
        <S.DrawerTitle variant="heading3">
          Informação sobre valores
        </S.DrawerTitle>
        <S.DrawerValues>
          <S.DrawerValueItem>
            <Typography variant="description">Valor original</Typography>
            <S.SkeletonSpan>
              {normalizeMoney(data?.clientTransaction?.netValue)}
            </S.SkeletonSpan>
          </S.DrawerValueItem>
          <S.DrawerValuesDivider />
          <S.DrawerValueItem>
            <Typography variant="description">
              Saldo atual disponível
            </Typography>
            <S.SkeletonSpan>{normalizeMoney(balance.current)}</S.SkeletonSpan>
          </S.DrawerValueItem>
          <S.DrawerValuesDivider />
          <S.DrawerValueItem>
            <Typography variant="description">Saldo a antecipar</Typography>
            <S.SkeletonSpan>
              {normalizeMoney(data?.advancementSimulation?.advancementValue)}
            </S.SkeletonSpan>
          </S.DrawerValueItem>
          <S.DrawerValuesDivider />
          <S.DrawerValueItem>
            <Typography variant="description">Custo da antecipação</Typography>
            <S.SkeletonSpan>
              {normalizeMoney(
                data?.advancementSimulation?.advancementRateValue
              )}
            </S.SkeletonSpan>
          </S.DrawerValueItem>
          <S.DrawerValuesDivider />
          <S.DrawerValueItem>
            <Typography variant="description">
              Total a ser antecipado
            </Typography>
            <S.SkeletonSpan>
              {normalizeMoney(
                data?.advancementSimulation?.advancementTotalCost
              )}
            </S.SkeletonSpan>
          </S.DrawerValueItem>
        </S.DrawerValues>
      </S.DrawerContent>
    </Drawer>
  );
};

AdvancementSimulationDetailsDrawer.propTypes = {
  balance: PropTypes.object.isRequired,
};

export default AdvancementSimulationDetailsDrawer;
