import { useEffect, useState } from 'react';
import { getPixPayment } from 'api/pix';
import { getBankBy } from 'api/bank-account';

export default function useFindBank(clientUUID, transactionUUID, pixKey) {
  const [pixPaymentApi, setPixPaymentApi] = useState({});
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [bank, setBank] = useState({});

  useEffect(() => {
    setLoadingScreen(true);
    getPixPayment(clientUUID, transactionUUID)
      .then((response) => {
        const data = response.data?.data?.attributes;
        setPixPaymentApi(data);

        if (pixKey && data?.credit_party?.bank) {
          getBankBy('ispb', data.credit_party.bank)
            .then((response) => {
              setBank(response.data.bank);
            })
            .catch(() => {
              setBank(data?.credit_party?.bank);
            });
        }

        setLoadingScreen(false);
      })
      .catch(() => {
        setLoadingScreen(false);
      });
  }, []);

  return {
    pixPaymentApi,
    loadingScreen,
    bank,
  };
}
