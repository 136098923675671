import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendCustomEvent } from '@useblu/blu-lytics';

import { Link, Input, Container } from '@useblu/ocean-react';
import TransactionAuthorizationConfirmation from 'store/ducks/transactionAuthorizationConfirmation';
import BTokenActions from 'store/ducks/bToken/actions';
import BatchPayments from 'store/ducks/batchPayments';
import { useAsyncFn } from 'react-use';
import { getUserInformation } from 'api/transaction-authorization';
import useRailsContext from 'hooks/useRailsContext';
import _ from 'lodash';
import {
  ElementsContainer,
  Title,
  MainContainer,
  AuthOption,
  OptionTitle,
  Form,
  SeparatorContainer,
  OptionDescription,
  LoadingText,
  BTokenProblem,
} from './ConfirmWithBTokenStyles';

import lockIcon from './images/ic-lock.svg';
import authorize from './images/authorize.svg';
import authorizeCode from './images/authorize-code.svg';

import BTokenModal from './BTokenModal';
import Spinner from './Spinner';
import KnowMore from './KnowMore';
import InfoBox from './InfoBox';

import ExpiredModal from './ExpiredModal';
import LivenessValidationModal from './LivenessValidationModal';

const ConfirmWithBToken = ({ token, uuid, clientTransactionUUID }) => {
  const { currentClientBalance, segmentKey } = useRailsContext();
  const data = useSelector((state) => state.transactionAuthorization);
  const { byId } = useSelector((state) => state.bToken);
  const batchPayment = useSelector((state) => state.batchPayments);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isExpiredModal, setIsExpiredModal] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [dynamicSpacing, setDynamicSpacing] = useState('auto');
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);
  const [code, setCode] = useState('');
  const [device, setDevice] = useState(null);
  const [livenessOpen, setLivenessOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const [userInformation, callUserInformation] = useAsyncFn(getUserInformation);

  const handleRequestBatchPayment = () => {
    dispatch(BatchPayments.batchPaymentsRequest());
  };

  const handlePerform = (_code) => {
    dispatch(
      TransactionAuthorizationConfirmation.transactionAuthorizationRequest(
        token,
        _code
      )
    );
  };

  const handleResendNotification = useCallback(() => {
    dispatch(
      TransactionAuthorizationConfirmation.resendNotificationRequest(token)
    );
  }, [dispatch, token]);

  const handleShow = useCallback(
    (_token) => {
      dispatch(
        TransactionAuthorizationConfirmation.showTransactionAuthorizationRequest(
          _token
        )
      );
    },
    [dispatch]
  );

  const handleDeviceBToken = () => {
    dispatch(BTokenActions.fetchBTokenByUserRequest());
  };

  useEffect(() => {
    handleDeviceBToken();
    callUserInformation();
    handleShow(token);
    dispatch(BatchPayments.clearBatchPayments());
  }, []);

  useEffect(() => {
    if (byId) {
      const device = _.find(byId, { status: 'active' });
      setDevice(device);
    }
  }, [byId]);

  useEffect(() => {
    if (data.liveness && !isExpiredModal) {
      setLivenessOpen(true);
    }
  }, [data.liveness, isExpiredModal]);

  useEffect(() => {
    if (code.length === 6) {
      handlePerform(code);
    }
    if (code.length <= 0) {
      setDynamicSpacing('normal');
    } else {
      setDynamicSpacing(10);
    }
  }, [code]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const pontualUrl = `/lojas/${uuid}/portabilidade-pontual-sucesso`;
    const automaticUrl = `/lojas/${uuid}/portabilidade-automatica-sucesso`;
    const alert = data?.data?.meta?.flash?.alert;

    if (data.done && data.is_portability) {
      if (alert) {
        return window.location.assign(
          `${pontualUrl}?flash_alert=${encodeURIComponent(alert)}`
        );
      }

      return window.location.assign(`${pontualUrl}`);
    }
    if (data.done && data.is_automatic_portability) {
      if (alert) {
        return window.location.assign(
          `${pontualUrl}?flash_alert=${encodeURIComponent(alert)}`
        );
      }

      return window.location.assign(`${automaticUrl}`);
    }
    if (data.done) {
      handleRequestBatchPayment();
      dispatch(
        BatchPayments.transactionData({
          ...data.data,
          uuid:
            data?.data?.batch_payment?.uuid ||
            data?.data?.client_transaction?.uuid,
        })
      );
      return setRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.done]);

  const getTransactionType = (transactionSlug) => {
    switch (transactionSlug) {
      case 'payment_to_bill':
        return { type: 'billet', billetType: 'paymentslip' };
      case 'fee_payment':
        return { type: 'billet', billetType: 'utilities' };
      case 'transfer_to_client_outflow':
        return { type: 'transfer', transferType: 'blu' };
      case 'transfer_to_bank_account':
        return { type: 'transfer', transferType: 'bank' };
      default:
        return { type: 'transfer', transferType: 'pix' };
    }
  };

  const sendEvents = () => {
    batchPayment?.data.client_transactions.forEach((transaction) => {
      const { type, transferType, billetType } = getTransactionType(
        transaction.transaction_category.slug
      );

      sendCustomEvent('payment_success', {
        authorization_type: 'btoken',
        billet_type: billetType,
        transfer_type: transferType,
        client_uuid: transaction.client.uuid,
        is_economic_group: transaction.client.economic_group,
        liveness_required: data?.data.liveness,
        schedule_option: transaction.payment_on_today ? 'today' : 'schedule',
        segment: segmentKey,
        total_value: transaction.gross_value,
        type,
        withdraw_all_money:
          parseFloat(currentClientBalance) <= batchPayment?.data.total_value,
      });
    });
  };

  useEffect(() => {
    if (batchPayment?.data?.uuid && redirect) {
      sendEvents();
      window.location.assign(
        `/lojas/${uuid}/pagamentos/${batchPayment.data.uuid}/show_result?bToken=true`
      );
    } else if (redirect && clientTransactionUUID) {
      window.location.assign(
        `/lojas/${uuid}/transacoes/${clientTransactionUUID}/confirmacao`
      );
    }
  }, [batchPayment, redirect, clientTransactionUUID, uuid]);

  useEffect(() => {
    if (data.error) {
      setHasError(data.error);
      setCode('');
    }
  }, [dispatch, data.error]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        data.expired ||
        new Date(data.expirationDate).getTime() <=
          new Date(Date.now()).getTime()
      ) {
        clearInterval(interval);
        setIsExpiredModal(true);
      } else {
        handleShow(token);
      }
    }, [2000]);

    return () => {
      clearInterval(interval);
    };
  }, [data.expired, data.expirationDate, handleShow, token]);

  const handleChange = (event) => {
    setCode(event.target.value);
    setHasError(false);
  };

  const resendNotificationLiveness = useCallback(() => {
    handleResendNotification();
  }, [handleResendNotification]);

  return (
    <>
      <Container
        className="ocean-iso"
        style={{ maxWidth: 764, margin: 'auto' }}
      >
        {typeof data.liveness === 'boolean' && !data.liveness && (
          <ElementsContainer>
            <Title>
              Validação de segurança{' '}
              <img src={lockIcon} alt="Icone de bloqueio" />
            </Title>
            <MainContainer>
              <AuthOption className="option">
                <img src={authorize} alt="Altorização" />
                <div className="info">
                  <OptionTitle className="option-title">
                    Autorize pela notificação no celular
                  </OptionTitle>
                  <OptionDescription>
                    Autorize a partir da notificação que acabamos de enviar para
                    o seu celular e aguarde a validação automática.
                  </OptionDescription>
                  <InfoBox text="É necessário ter as notificações do app Blu habilitadas nas configurações do celular." />
                </div>
              </AuthOption>
              <SeparatorContainer>
                <hr /> <span>ou</span> <hr />
              </SeparatorContainer>
              <AuthOption className="option">
                <img src={authorizeCode} alt="Código de autorização" />
                <div className="info">
                  <OptionTitle className="option-title">
                    Digite o código gerado no app
                  </OptionTitle>
                  <OptionDescription>
                    Selecione ”bToken” na tela inicial do aplicativo e digite o
                    código no campo abaixo.{' '}
                    <Link
                      role="button"
                      style={{ fontSize: 14 }}
                      onClick={() => setIsAboutModalOpen(true)}
                    >
                      Saiba mais
                    </Link>
                  </OptionDescription>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handlePerform(code);
                    }}
                  >
                    <Input
                      label=""
                      type="number"
                      pattern="\d*"
                      id="b-token-code"
                      data-testid="code"
                      autoComplete="off"
                      style={{
                        maxWidth: 160,
                        letterSpacing: dynamicSpacing,
                      }}
                      error={hasError}
                      helperText={hasError ? 'Código inválido' : ''}
                      onChange={handleChange}
                      value={code}
                      placeholder="Código bToken"
                      disabled={code.length === 6}
                    />
                  </Form>
                </div>
              </AuthOption>
            </MainContainer>
            {userInformation.value && !userInformation?.value?.forceBtoken && (
              <BTokenProblem>
                Problemas com o bToken?{' '}
                <Link
                  style={{ fontSize: 12 }}
                  href={`${location.origin}/lojas/${uuid}/autorizacoes/${token}/resend_confirmation_mailer`}
                >
                  Autorizar por e-mail e SMS
                </Link>
              </BTokenProblem>
            )}
          </ElementsContainer>
        )}
        <LivenessValidationModal
          uuid={uuid}
          device={device}
          isOpen={livenessOpen}
          resendNotification={resendNotificationLiveness}
          resendError={data.resendError}
        />
        <BTokenModal
          isDrawerOnMobile={false}
          customWidth={288}
          isOpen={data.loading || isLoading}
          close={() => setIsLoading(false)}
        >
          <Spinner />
          <LoadingText>Validando bToken...</LoadingText>
        </BTokenModal>
        <KnowMore
          isOpen={isAboutModalOpen}
          close={() => setIsAboutModalOpen(false)}
        />
        <ExpiredModal
          isOpen={isExpiredModal}
          close={() => window.location.assign('/')}
          canceledBy={data.canceledBy}
        />
      </Container>
    </>
  );
};

ConfirmWithBToken.propTypes = {
  uuid: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  clientTransactionUUID: PropTypes.string,
};

ConfirmWithBToken.defaultProps = {
  clientTransactionUUID: null,
};

export default ConfirmWithBToken;
